<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Music</h1>
        </div>
      </div>
    </div>
    <div class="main container">
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-quarter" v-for="region in music" :key="region.id">
          <a target="_blank" rel="noopener noreferrer" :href="region.link">
            <div class="musicBox">
              <p class="is-size-4 description">{{region.region}}</p>
            </div>
          </a>
        </div>
        <div class="column is-one-quarter">
          <div class="musicBox">
            <p class="is-size-4 description">+ Add Music</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MusicService from "../services/musicService"

export default {
  name: "Music",
  data() {
    return {
      music: []
    };
  },
  async mounted() {
    this.music = await MusicService.getAll();
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin-top: 30px;
}

.org-description {
  margin-top: 50px;
}

.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.musicBox {
  background-color: #eee;
  border-radius: 2px;
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  filter: drop-shadow(0 0 0.1rem black);
  padding: 30px 65px;
  margin: 10px;
  text-align: center;
  p .description{
    color: inherit;
    text-decoration: none !important;
  }
}
.musicBox:hover {
  opacity: 1;
}
</style>